import React from "react";
import Layout from "../components/Layout";
import FeaturedArticle from "../components/newsroom/FeaturedArticle";
import NewsroomHero from "../components/newsroom/NewsroomHero";
import OtherArticles from "../components/newsroom/OtherArticles";
import Newsletter from "../components/shared/Newsletter/Newsletter";

const seo = {
  title: "News",
  description:
    "Stay upto date with the latest news at Plastometrex - product releases, updates, collaborations and more ",
};

function NewsroomPage() {
  return (
    <Layout seo={seo} backgroundColorUnderneath="blue">
      <NewsroomHero />
      <FeaturedArticle />
      <OtherArticles />
      <Newsletter />
    </Layout>
  );
}

export default NewsroomPage;
