import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import {
  date,
  heading100,
  paragraphTextMedium,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import KnowledgeCentrePreview from "../knowledgeCentre/KnowledgeCentrePreview";

const OtherArticles = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/newsroom/" }
          frontmatter: { featured: { eq: false } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "D MMMM YYYY")
              image {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Container>
      <KnowledgeCentrePreview data={data} mobileHidden={false} />
    </Container>
  );
};

export default OtherArticles;

const Container = styled("div", {});
