import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import {
  heading200,
  heading500,
  paragraphTextLarge,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

const title = `Newsroom`;
const byline = `Stay up to date with all the latest news at Plastometrex.`;

const NewsroomHero = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "newsroom_background.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);
  const heroImage = getImage(data.backgroundImage) as IGatsbyImageData;

  return (
    <Container>
      <ImageWrapper>
        <Image image={heroImage} objectFit={"cover"} loading={"eager"} alt="" />
      </ImageWrapper>
      <TextContent>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
      </TextContent>
    </Container>
  );
};

export default NewsroomHero;

const Container = styled("div", {
  width: "100%",
  margin: "auto",
  backgroundColor: "$blue100",
  position: "relative",
  "@md": {
    width: "100%",
    maxWidth: "none",
    paddingBottom: 300,
    textAlign: "center",
    marginTop: -80,
    position: "relative",
    height: 600,
  },
});
const Title = styled("h1", {
  ...heading200,
  margin: 0,
  paddingTop: 38,
  paddingBottom: 247,
  textAlign: "center",
  color: "$white",
  "@md": {
    ...heading500,
    color: "$white",
    padding: 0,
  },
});
const Byline = styled("p", {
  ...paragraphTextLarge,
  display: "none",
  paddingTop: 20,
  "@md": {
    display: "block",
    color: "$white",
    width: "90%",
    margin: "0 auto",
  },
});

const ImageWrapper = styled("div", {
  position: "absolute",
  left: 0,
  right: 0,
  width: "100%",
  height: 600,
  zIndex: 0,
  overflow: "hidden",
});

const TextContent = styled("div", {
  zIndex: 1,
  position: "relative",
  paddingTop: 36,
  "@md": {
    paddingTop: 157,
  },
});

const Image = styled(GatsbyImage, {
  height: "100%",
});
