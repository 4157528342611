import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import {
  date,
  heading100,
  paragraphTextMedium,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import { slugify } from "../../utils/slugify";

const FeaturedArticle = () => {
  const getFeaturedArticleWithFallbackToLatestNotFeatured = (): Record<string, any> | undefined => {
    const data = useStaticQuery(graphql`
    {
      featured: allMarkdownRemark(
        filter: {
          fileAbsolutePath: {regex: "/newsroom/"}
          frontmatter: { featured: { eq: true } }
        }
        sort: {fields: [frontmatter___date], order: DESC}
        limit: 1
      ) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "D MMMM YYYY")
              description
              featured
              image {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
        }
      }
      nonFeatured: allMarkdownRemark(
        filter: {
          fileAbsolutePath: {regex: "/newsroom/"}
          frontmatter: { featured: { eq: false } }
        }
        sort: {fields: [frontmatter___date], order: DESC}
        limit: 1
      ) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "D MMMM YYYY")
              description
              featured
              image {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
        }
      }
    }
    `);


    return data.featured.edges[0]?.node.frontmatter ?? data.nonFeatured.edges[0]?.node.frontmatter;
  }


  const article = getFeaturedArticleWithFallbackToLatestNotFeatured();

  if (article !== undefined) {
    const image = getImage(article.image.childImageSharp) as IGatsbyImageData;
    return (
      <Container to={`/newsroom/${slugify(article.title)}`}>
        <GatsbyImage image={image} alt={article.description} />
        <TextContent>
          <FeaturedIcon>Featured</FeaturedIcon>
          <ArcticleTitle>{article.title}</ArcticleTitle>
          <ArcticleDate>{article.date}</ArcticleDate>
          <ArcticleDescription>{article.description}</ArcticleDescription>
        </TextContent>
      </Container>
    );
  }

  return <></>;
};

export default FeaturedArticle;

const Container = styled(Link, {
  width: "95%",
  margin: "auto",
  maxWidth: 600,
  marginTop: -210,
  backgroundColor: "$white",
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  marginBottom: 18,
  position: "relative",
  display: "block",
  "@lg": {
    width: "95%",
    display: "flex",
    maxWidth: 1140,
    zIndex: 1,
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
  },
});

const TextContent = styled("div", {
  padding: "26px 20px",
  "@lg": {
    maxWidth: "50%",
  },
});

const FeaturedIcon = styled("div", {
  fontFamily: "$opensans",
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "20px",
  color: "$blue100",
  backgroundColor: "$blue10",
  borderRadius: 10,
  padding: "2px 11px",
  marginBottom: 10,
  display: "inline-block",
});

const ArcticleTitle = styled("h4", {
  ...heading100,
  margin: 0,
  marginBottom: 8,
});

const ArcticleDate = styled("p", {
  ...date,
});

const ArcticleDescription = styled("p", {
  ...paragraphTextMedium,
  marginTop: 10,
});
